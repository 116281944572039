import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { City } from 'country-state-city';
import { useEffect, useState } from 'react';
import { latLngToCell, gridDisk, cellsToMultiPolygon } from 'h3-js';

function getDisk(latitude, longitude, resolution, k) {
    const index = latLngToCell(latitude, longitude, resolution || 3);
    return gridDisk(index, k || 1);
}

export default function CitySearch(props) {
    const [cities, setCities] = useState([]);

    useEffect(() => {
        const cities = City.getCitiesOfCountry("US")
        setCities(cities);
    }, [])

    const onSelect = (item) => {
        const disk = getDisk(item.latitude, item.longitude);
        props.onSearchResultSelect(disk);
    }

    const onODChange = (e) => {
        props.setDestinationChecked(e.target.checked);
    }

    const formatResult = (item) => {
        return (
            <span>{item.name}, {item.stateCode}</span>
        )
    }

    return <div style={{ width: 400 }} className="bg-transparent grid grid-cols-4">
        <div className="col-span-3">
            <ReactSearchAutocomplete
                items={cities}
                onSelect={onSelect}
                formatResult={formatResult}
                placeholder="Search for a city"
                showClear={false}
                showIcon={true}
                fuseOptions={{
                    shouldSort: true,
                    keys: [
                        "name", "stateCode"
                    ]
                }}
            />
        </div>
        <div className="font-light col-span-1 text-xs" style={{ zIndex: 10001 }}>
            <label htmlFor="ODToggle" className="-ml-44 inline-flex items-center p-2 cursor-pointer text-gray-800 rounded rounded-3xl">
                <input id="ODToggle" type="checkbox" className="hidden peer" onChange={onODChange} />
                    <span className="p-2 rounded-l-3xl bg-green-500 peer-checked:bg-gray-300">O</span>
                    <span className="p-2 rounded-r-3xl bg-gray-300 peer-checked:bg-red-500">D</span>
            </label>
        </div>
    </div>
}