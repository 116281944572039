import {
  PencilSquareIcon,
  PlayIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { Tooltip } from "react-tooltip";
import DropdownMenu from "./DropdownMenu";
import DropdownQueries from "./DropdownQueries";
import DropdownCheckbox from "./DropdownCheckbox";
import DropdownRecordType from "./DropdownRecordType";
import DropdownDistance from "./DropdownDistance";
import DropdownLoads from "./DropdownLoads";
import DropdownRPM from "./DropdownRPM";
import queries from "./data/Queries";

const howDoIFilterByLocationMessage = `
Hold the Control key (Mac users: Control, not Command), click and drag across the map to select areas. Your selection will be used to show only results (one-way or network moves) where the **first leg** starts or ends in the selected areas.

To switch between origin and destination area selection, use the toggle button (O|D) in the search bar. Click D to choose destination selection, click O to choose origin selection. You can select multiple origin or destination areas.

You can also use the search bar to search for a location. Click the search bar, type a location name, and click the search result to select it. An area will be drawn around the location. Use the toggle button (O|D) to choose whether the result will be used for origin or destination selection.
`

function Spinner() {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        className="inline w-4 h-4 mb-0.5 text-sky-200 animate-spin dark:text-lightgray-900 fill-green-800"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  );
}

export default function Drawer(props) {
  return (
    <div className="relative">
      <div className="">
        <DropdownCheckbox
          name="Shippers"
          availableShippers={props.availableShippers}
          selectedShippers={props.selectedShippers}
          onSelectShippers={props.onSelectShippers}
        />
        <DropdownQueries
          name={props.queryOption ? props.queryOption.name : "Query"}
          options={queries}
          onClick={props.setQueryOption}
          restoreQueryOption={props.restoreQueryOption}
          sessionLocalQueries={props.sessionLocalQueries}
        />
        <DropdownMenu
          name={props.queryType ? props.queryType.name : "Query"}
          options={props.queryTypes}
          onClick={props.setQueryType}
        />
        <div className="inline-block inline-flex mx-2">
          <label className="relative inline-flex items-start cursor-pointer">
            <input type="checkbox" onChange={() => props.onSetBalanced(!props.balanced)} />
            <span className="ml-1 -mt-1 text-sm font-light">Balanced?</span>
          </label>
          <InformationCircleIcon data-tooltip-id="balanced-tooltip" data-tooltip-content="Legs with similar annualized volume" className="h-4 w-4 fill-slate-400" />
          <Tooltip id="balanced-tooltip" className="text-sm" />
        </div>
        <DropdownDistance name="Miles"
          minDistance={props.minDistance}
          maxDistance={props.maxDistance}
          onMinDistanceChange={props.onMinDistanceChange}
          onMaxDistanceChange={props.onMaxDistanceChange}
        />
        <DropdownLoads name="Loads"
          minLoads={props.minLoads}
          maxLoads={props.maxLoads}
          onMinLoadsChange={props.onMinLoadsChange}
          onMaxLoadsChange={props.onMaxLoadsChange}
        />
        <DropdownRPM name="RPM"
          minRPM={props.minRPM}
          maxRPM={props.maxRPM}
          onMinRPMChange={props.onMinRPMChange}
          onMaxRPMChange={props.onMaxRPMChange}
        />
        <DropdownMenu
          name={props.selectedEquipmentClass ? props.selectedEquipmentClass.name : "Query"}
          options={props.equipmentClasses}
          onClick={props.onEquipmentClassChange}
        />
        <DropdownRecordType
          name="Record Type"
          recordTypeOptions={props.recordTypeOptions}
          selectedRecordTypes={props.selectedRecordTypes}
          onSelectRecordType={props.onSelectRecordType}
        />
        {
          props.backend.name == 'Execution' ? <DropdownMenu
            name={props.selectedExecutionStrategy ? props.selectedExecutionStrategy.name : "Pickup/Delivery Matching"}
            options={props.executionStrategies}
            onClick={props.onExecutionStrategyChange}
          /> : <></>
        }

      </div>
      <div className="flex flex-row mt-1">
        <button
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
          onClick={props.setShowQuery}
        >
          <PencilSquareIcon className="h-4 w-4 fill-sky-700" />
        </button>
        <button
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
          onClick={props.onRunQuery}
        >
          {props.inProgress ? (
            <Spinner />
          ) : (
            <PlayIcon className="h-4 w-4 fill-green-900" />
          )}
          <div className="flex justify-between w-full">
            <span className="mx-1">Run</span>
            <span className="font-light text-s">
              {props.runTime ? `${props.runTime}s` : ""}
            </span>
          </div>
        </button>
        <button
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
          onClick={props.onSaveQuery}
        >
          <span>Save query</span>
        </button>
        <button
          className="inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
          onClick={() => alert(howDoIFilterByLocationMessage)}
        >
          <InformationCircleIcon className="h-4 w-4 fill-green-800 inline-block" /> How do I filter by location?
        </button>
      </div>
    </div>
  );
}
