import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DropdownCheckbox(props) {
  const onInputChange = (e, option) => {
    const options = e.target.checked
      ? [...props.selectedRecordTypes, option]
      : props.selectedRecordTypes.filter((item) => item.name !== option.name);
    props.onSelectRecordType(options);
  };

  const onSelectAll = () => {
    props.onSelectRecordType([...props.recordTypeOptions]);
  };

  const onClearAll = () => {
    props.onSelectRecordType([]);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 px-2 text-sm font-medium text-gray-700 hover:bg-gray-100">
          <span className="truncate text-left">{props.name}</span>
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute max-h-64 overflow-y-scroll left-0 z-10 mt-2 w-96 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex flex-col mx-2 my-1">
            <div>
              <button
                className="relative items-center rounded-md border border-gray-300 bg-white m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
                onClick={onSelectAll}
              >
                Select all
              </button>
              <button
                className="relative items-center rounded-md border border-gray-300 bg-white m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
                onClick={onClearAll}
              >
                Clear all
              </button>
            </div>
            {props.recordTypeOptions.map((option, i) => (
              <div
                key={`shipper-option-${option.name}`}
                className="flex items-center p-2 rounded hover:bg-gray-100 hover:bg-gray-200"
              >
                <input
                  id={`checkbox-item-${i}`}
                  checked={props.selectedRecordTypes
                    .map((item) => item.name)
                    .includes(option.name)}
                  type="checkbox"
                  value={option.name}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  onChange={(e) => onInputChange(e, option)}
                />
                <label
                  htmlFor={`checkbox-item-${i}`}
                  className="w-full ml-2 text-sm font-light rounded"
                >
                  {option.displayName}
                </label>
              </div>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
