import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DropdownMenu(props) {

    const [descending, setDescending] = useState(true)
    const updateDescending = function (e) {
        setDescending(e.target.checked)
        props.onClick(props.option, e.target.checked)
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 px-2 text-sm font-medium text-gray-700 hover:bg-gray-100">
                    <span className="truncate text-left w-28">{props.name}</span>
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute max-h-64 overflow-y-scroll right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ zIndex: 10000 }}>
                    <div className="absolute font-medium text-xs w-full">
                        <label htmlFor="AscDescToggle" className="w-full inline-flex items-center cursor-pointer text-gray-800">
                            <input id="AscDescToggle" type="checkbox" className="hidden peer" checked={descending} onChange={(e) => updateDescending(e)} />
                            <span className="py-2 w-1/2 text-center bg-gray-200 peer-checked:bg-sky-300 hover:bg-gray-300">Highest first</span>
                            <span className="py-2 w-1/2 text-center bg-sky-300 peer-checked:bg-gray-200 hover:peer-checked:bg-gray-300">Lowest first</span>
                        </label>
                    </div>
                    <div className="py-1">{
                        props.options.map((option, i) => <Menu.Item key={i} onClick={() => { props.onClick(option, descending) }}>
                            {({ active }) => (
                                <a
                                    href="#"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm'
                                    )}
                                >
                                    {option.displayName || option.name} {option.id !== undefined ? `(${option.id})` : ''}
                                </a>
                            )}
                        </Menu.Item>
                        )
                    }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
