import {
  MapContainer,
  TileLayer,
  useMap,
  Polygon,
  LayersControl,
} from "react-leaflet";
// needs to be imported for area selection to work
import SelectArea from "leaflet-area-select";
import { useState, useEffect } from "react";
import Lane from "./Lane";

import "leaflet/dist/leaflet.css";
import * as turf from "@turf/turf";
import { polygonToCells, cellsToMultiPolygon } from "h3-js";

const H3_SELECTION_RESOLUTION = 3;

const cartLaneColors = [
  "#1f77b4",
  "#aec7e8",
  "#ff7f0e",
  "#ffbb78",
  "#2ca02c",
  "#98df8a",
  "#d62728",
  "#ff9896",
  "#9467bd",
  "#c5b0d5",
  "#8c564b",
  "#c49c94",
  "#e377c2",
  "#f7b6d2",
  "#7f7f7f",
  "#c7c7c7",
  "#bcbd22",
  "#dbdb8d",
  "#17becf",
  "#9edae5",
];

const getCartLaneColor = (lane) => {
  return cartLaneColors[lane.owner % cartLaneColors.length];
};

function AreaSelection(props) {
  const map = useMap();
  map.selectArea.enable();

  map.on("areaselected", (e) => {
    const poly = turf.bboxPolygon(
      e.bounds
        .toBBoxString()
        .split(",")
        .map((x) => parseFloat(x))
    );
    const cells = polygonToCells(
      poly.geometry.coordinates,
      H3_SELECTION_RESOLUTION,
      true
    );
    if (props.destinationChecked) {
      props.onOriginAreaSelect([...props.originArea]);
      props.onDestinationAreaSelect([...props.destinationArea, ...cells]);
    } else {
      props.onOriginAreaSelect([...props.originArea, ...cells]);
      props.onDestinationAreaSelect([...props.destinationArea]);
    }
  });

  return null;
}

function FlyTo(props) {
  const map = useMap();
  if (props.result) {
    map.panTo(props.center, props.zoom, {
      animate: true,
      duration: 2,
    });
  }
}

function SelectionPolygon(props) {
  const poly = (
    <Polygon
      positions={props.positions}
      pathOptions={props.pathOptions}
      clickable={true}
    />
  );

  return poly;
}

function getLanes(result, cart) {
  if (result && result.lanes && result.lanes.length > 0) {
    return result.lanes.map((lane, i) => (
      <Lane key={i} lane={lane} color={lane.color} />
    ));
  } else if (cart && cart.length > 0) {
    const lanes = [];
    cart.forEach((result, i) => {
      if (result && result.lanes && result.lanes.length > 0) {
        for (let j = 0; j < result.lanes.length; j++) {
          lanes.push(
            <Lane
              key={i + j}
              lane={result.lanes[j]}
              isCartLane
              color={getCartLaneColor(result.lanes[j])}
            />
          );
        }
      }
    });
    return lanes;
  } else {
    return <></>;
  }
}

function Map(props) {
  const [center, setCenter] = useState([37.11, -101.29]);
  const [zoom, setZoom] = useState(4);

  useEffect(() => {
    if (
      props.result &&
      props.result.lanes &&
      props.result.lanes.length > 0 &&
      props.result.lanes[0]
    ) {
      if (
        props.result.lanes[0].geometry &&
        props.result.lanes[0].geometry.coordinates
      ) {
        const start = props.result.lanes[0].geometry.coordinates[0];
        const end = props.result.lanes
          .slice(-1)[0]
          .geometry.coordinates.slice(-1)[0];
        const center = turf.center(turf.lineString([start, end])).geometry
          .coordinates;
        setCenter(center);

        if (
          props.result.lanes.reduce((acc, lane) => acc + lane.miles, 0) > 1500
        ) {
          setZoom(4);
        }
      }
    }
  }, [props.result]);

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      zoomControl={false}
      scrollWheelZoom={true}
      style={{ height: "100%", width: "100%" }}
    >
      <LayersControl position="bottomright">
        <LayersControl.BaseLayer name="OpenStreetMap">
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Stamen Toner Lite">
          <TileLayer url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png" />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked name="CartoDB Positron">
          <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
        </LayersControl.BaseLayer>
      </LayersControl>
      <AreaSelection
        originArea={props.originArea}
        destinationArea={props.destinationArea}
        destinationChecked={props.destinationChecked}
        onOriginAreaSelect={props.onOriginAreaSelect}
        onDestinationAreaSelect={props.onDestinationAreaSelect}
      />
      <FlyTo center={center} zoom={zoom} result={props.result} />
      {getLanes(props.result, props.cart)}
      {props.originArea && props.originArea.length > 0 ? (
        <SelectionPolygon
          positions={cellsToMultiPolygon(props.originArea)}
          pathOptions={{
            color: "forestgreen",
            weight: 1,
            opacity: 0.5,
            fillOpacity: 0.1,
          }}
        />
      ) : (
        <></>
      )}
      {props.destinationArea && props.destinationArea.length > 0 ? (
        <SelectionPolygon
          positions={cellsToMultiPolygon(props.destinationArea)}
          pathOptions={{
            color: "#B73239",
            weight: 1,
            opacity: 0.5,
            fillOpacity: 0.1,
          }}
        />
      ) : (
        <></>
      )}
    </MapContainer>
  );
}

export default Map;
