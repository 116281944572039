import Map from "./map/Map";
import Navbar from "./Drawer";
import Drawer from "./Navbar";
import { useState, useEffect, useCallback } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import queries from "./data/Queries";
import Results from "./Results";
import * as jsonexport from "jsonexport/dist";
import fileDownload from "js-file-download";
import { useAuth0 } from "@auth0/auth0-react";
import { GraphQuery, ShipperGraphQuery, logCart } from "./data/Models";
import CitySearch from "./CitySearch";
import { XCircleIcon, ArrowPathIcon } from "@heroicons/react/20/solid";

const backends = [
  { name: "Analytics", displayName: "Historical" },
  { name: "Execution", displayName: "Execution" },
];

const queryTypes = [
  {
    name: "Internal",
    displayName: "Internal (all legs belong to selected shippers)",
  },
  {
    name: "External",
    displayName: "External (only first leg belongs to selected shippers)",
  },
];

const equipmentClasses = [
  { name: "Dry", displayName: "All legs DRY" },
  { name: "Reefer", displayName: "All legs REEFER" },
  { name: "DryReefer", displayName: "First leg DRY, other leg(s) REEFER" },
  { name: "ReeferDry", displayName: "First leg REEFER, other leg(s) DRY" },
];

const orderByOptions = [
  { name: "Total spend", property: "total_spend" },
  { name: "Total annualized loads", property: "total_loads" },
  { name: "Total miles", property: "total_miles" },
  { name: "Total deadhead", property: "total_deadhead" },
  { name: "Average RPM", property: "average_rpm" },
];

const recordTypeOptions = [
  { name: "SHIPMENT", displayName: "Historical shipment" },
  { name: "LANE", displayName: "Historical lane" },
  { name: "RFP", displayName: "RFP" },
];

// execution only
const executionStrategies = [
  {
    name: "Level 1",
    displayName:
      "Level 1: Each delivery is before, or on the same day, as next pickup in the network move.",
  },
  {
    name: "Level 2",
    displayName:
      "Level 2: Each delivery is within 8 hours + deadhead driving time (before OR after) of next pickup in the network move.",
  },
];

const formatForDownload = (result, queryOption, queryType, backend) => {
  const output = {
    network_move: queryOption.name,
    move_type: queryType.name,
    data_source: backend.name,
    approximate_total_miles: result.total_miles,
    approximate_total_deadhead: result.total_deadhead || 0,
  };

  for (let i = 0; i < result.lanes.length; i++) {
    let lane = result.lanes[i];
    output[`l${i}_lane_id`] = lane.id;
    output[`l${i}_owner`] = lane.owner;
    output[`l${i}_owner_name`] = lane.owner_name;
    output[`l${i}_equipment_class`] = lane.equipment_class;
    output[`l${i}_o_name`] = lane.o_name;
    output[`l${i}_d_name`] = lane.d_name;
    output[`l${i}_annualized_total_ships`] = lane.lane_annualized_total_ships;
    output[`l${i}_miles`] = lane.miles;
    output[`l${i}_mean_spend`] = lane.mean_spend;
    output[`l${i}_mean_rpm`] =
      lane.mean_spend && lane.miles
        ? (lane.mean_spend / lane.miles).toFixed(2)
        : null;
    output[`l${i}_meta`] =
      lane && lane.meta ? JSON.stringify(lane.meta).replaceAll("\\", "") : null;
  }

  return output;
};

export default function Container() {
  const [inProgress, setInProgress] = useState(false);
  const [runTime, setRunTime] = useState(null);
  const [error, setError] = useState(null);
  const [showQuery, setShowQuery] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [sessionLocalQueries, setSessionLocalQueries] = useState([]);
  const [destinationChecked, setDestinationChecked] = useState(false);

  const [queryOption, setQueryOption] = useState(queries[0]);
  const [query, setQuery] = useState(null);
  const [queryType, setQueryType] = useState(queryTypes[0]);
  const [orderBy, setOrderBy] = useState(null);

  const [originArea, setOriginArea] = useState([]);
  const [destinationArea, setDestinationArea] = useState([]);

  const [selectedBackend, setSelectedBackend] = useState(backends[0]);
  const [results, setResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState(null);
  const [cartExport, setCartExport] = useState([]);
  const [cart, setCart] = useState([]);
  const [balanced, setBalanced] = useState(false);

  const [minDistance, setMinDistance] = useState(0);
  const [maxDistance, setMaxDistance] = useState(3000);

  const [minRPM, setMinRPM] = useState(0);
  const [maxRPM, setMaxRPM] = useState(10);

  const [minLoads, setMinLoads] = useState(0);
  const [maxLoads, setMaxLoads] = useState(15000);

  const [availableShippers, setAvailableShippers] = useState([]);
  const [selectedShippers, setSelectedShippers] = useState([
    {
      name: "ACE HARDWARE",
      id: 17858,
    },
  ]);
  const [selectedRecordTypes, setSelectedRecordTypes] = useState([
    recordTypeOptions[0],
  ]);

  const [selectedEquipmentClass, setSelectedEquipmentClass] = useState(
    equipmentClasses[0]
  );
  const [selectedExecutionStrategy, setSelectedExecutionStrategy] = useState(
    executionStrategies[0]
  );

  const onQueryChange = useCallback((value) => {
    setQuery(value);
  });

  const onOriginAreaSelect = (cells) => {
    setOriginArea([...new Set(cells)]);
    setResults([]);
    setSelectedResult(null);
  };

  const onDestinationAreaSelect = (cells) => {
    setDestinationArea([...new Set(cells)]);
    setResults([]);
    setSelectedResult(null);
  };

  const onSearchResultSelect = (cells) => {
    if (destinationChecked) {
      setDestinationArea([...new Set([...destinationArea, ...cells])]);
    } else {
      setOriginArea([...new Set([...originArea, ...cells])]);
    }
  };

  const onOriginAreaClear = () => {
    setOriginArea([]);
  };

  const onDestinationAreaClear = () => {
    setDestinationArea([]);
  };

  const onOriginDestinationSwap = () => {
    const temp = [...originArea];
    setOriginArea([...destinationArea]);
    setDestinationArea(temp);
  };

  const onSaveQuery = () => {
    const queryName = prompt("Query name:");
    if (queryName !== null) {
      const savedQuery = {
        name: queryName,
        query: query,
        shipperOptions: selectedShippers,
        queryType: queryType,
        originArea: originArea,
        destinationArea: destinationArea,
        balanced: balanced,
        distance: [minDistance, maxDistance],
        rpm: [minRPM, maxRPM],
        loads: [minLoads, maxLoads],
        equipmentClass: selectedEquipmentClass,
        recordTypes: selectedRecordTypes,
      };
      // console.log("Saved query:", savedQuery);
      const localQueries = JSON.parse(
        localStorage.getItem("localQueries") || "[]"
      );
      localQueries.push(savedQuery);
      // console.log("Local queries:", localQueries);
      localStorage.setItem("localQueries", JSON.stringify(localQueries));
      setSessionLocalQueries([...sessionLocalQueries, savedQuery]);
    }
  };

  const restoreQueryOption = (option) => {
    setQueryOption(option);
    setQueryType(option.queryType);
    setOriginArea(option.originArea);
    setDestinationArea(option.destinationArea);
    setBalanced(option.balanced);
    setMinDistance(option.distance[0]);
    setMaxDistance(option.distance[1]);
    setMinRPM(option.rpm[0]);
    setMaxRPM(option.rpm[1]);
    setMinLoads(option.loads[0]);
    setMaxLoads(option.loads[1]);
    setSelectedShippers(option.shipperOptions);
    setSelectedEquipmentClass(option.equipmentClass);
    setSelectedRecordTypes(option.recordTypes);
  };

  const onClearCart = () => {
    if (window.confirm("Are you sure you want to clear your cart?")) {
      setCart([]);
      setCartExport([]);
    }
  };

  const onSetResult = (result) => {
    for (const result of results) {
      result.selected = false;
    }
    result.selected = true;
    setSelectedResult(result);
  };

  const onAddToCart = (result) => {
    setCart([...cart, result]);
    setCartExport([
      ...cartExport,
      formatForDownload(result, queryOption, queryType, selectedBackend),
    ]);
  };

  const onAddAllToCart = () => {
    setCart([...cart, ...results]);
    setCartExport([
      ...cartExport,
      ...results.map((result) =>
        formatForDownload(result, queryOption, queryType, selectedBackend)
      ),
    ]);
  };

  const onShowCartOnMap = () => {
    setSelectedResult(null);
  };

  const onDownloadCart = () => {
    jsonexport(cartExport, function (err, csv) {
      if (err) return console.log(err);
      return new Blob([csv], { type: "text/csv" });
    });

    getAccessTokenSilently()
      .then((token) => {
        logCart(cartExport, token);
      })
      .then(() => jsonexport(cartExport))
      .then((blob) => {
        fileDownload(blob, "network-moves-cart.csv");
      });
  };

  const onRunQuery = () => {
    const startTime = performance.now();
    setInProgress(true);
    setRunTime(null);
    setResults([]);
    setError(null);
    setOrderBy(null);

    getAccessTokenSilently().then((token) => {
      new GraphQuery(query, token, selectedBackend)
        .run()
        .then((results) => {
          setInProgress(false);
          setResults(results);
          setRunTime(((performance.now() - startTime) / 1000).toFixed(2));
          if (results && results.length == 0) {
            setError({
              message: "No results found, try adjusting query filters.",
            });
          }
        })
        .catch((error) => {
          setInProgress(false);
          setRunTime(null);
          setError(error);
        });
    });
  };

  const onOrderResults = (option, descending) => {
    setOrderBy(option);
    setResults(
      [...results].sort((a, b) => {
        if (!descending) {
          return a[option.property] - b[option.property];
        } else {
          return b[option.property] - a[option.property];
        }
      })
    );
    console.log(option, descending);
  };

  const onBackendChange = (backend) => {
    setSelectedBackend(backend);
    getAccessTokenSilently().then((token) => {
      new ShipperGraphQuery(token, backend).run().then((data) => {
        setAvailableShippers(data);
      });
    });
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      new ShipperGraphQuery(token, selectedBackend).run().then((data) => {
        setAvailableShippers(data);
      });
    });
  }, []);

  useEffect(() => {
    setQuery(
      queryOption.getQuery(
        selectedShippers,
        queryType,
        originArea,
        destinationArea,
        balanced,
        [minDistance, maxDistance],
        [minRPM, maxRPM],
        [minLoads, maxLoads],
        selectedEquipmentClass,
        selectedBackend.name,
        selectedExecutionStrategy.name,
        selectedRecordTypes
      )
    );
  }, [
    queryOption,
    selectedShippers,
    queryType,
    originArea,
    destinationArea,
    balanced,
    minDistance,
    maxDistance,
    minRPM,
    maxRPM,
    minLoads,
    maxLoads,
    selectedEquipmentClass,
    selectedBackend,
    selectedExecutionStrategy,
    selectedRecordTypes,
  ]);

  return (
    <div className="w-screen max-h-screen h-screen">
      <div className="h-16">
        <Drawer
          results={results}
          cart={cart}
          onDownloadCart={onDownloadCart}
          onClearCart={onClearCart}
          onAddAllToCart={onAddAllToCart}
          onShowCartOnMap={onShowCartOnMap}
          backends={backends}
          selectedBackend={selectedBackend}
          onBackendChange={onBackendChange}
        />
      </div>
      <div className="grid grid-cols-12 gap-0 h-[calc(100vh-5rem)]">
        <div className="col-span-6 relative">
          {originArea && originArea.length > 0 && (
            <button
              className="absolute top-2 right-2 w-64 text-left rounded-md border border-gray-300 bg-slate-100 m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-300"
              style={{ zIndex: 10000 }}
              onClick={() => onOriginAreaClear()}
            >
              <span className="font-medium inline-block">
                <XCircleIcon className="h-4 w-4 mr-1 inline-block fill-red-700" />{" "}
                Clear <span className="text-green-500">origin</span> area
                selection
              </span>
            </button>
          )}
          {destinationArea && destinationArea.length > 0 && (
            <button
              className="absolute top-10 right-2  w-64 rounded-md text-left border border-gray-300 bg-slate-100 m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-300"
              style={{ zIndex: 10000 }}
              onClick={() => onDestinationAreaClear()}
            >
              <span className="font-medium inline-block">
                <XCircleIcon className="h-4 w-4 mr-1 inline-block fill-red-700" />{" "}
                Clear <span className="text-red-500">destination</span> area
                selection
              </span>
            </button>
          )}
          {originArea &&
            originArea.length > 0 &&
            destinationArea &&
            destinationArea.length > 0 && (
              <button
                className="absolute top-16 mt-3 right-2 w-64 rounded-md text-left border border-gray-300 bg-slate-100 m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-300"
                style={{ zIndex: 10000 }}
                onClick={() => onOriginDestinationSwap()}
              >
                <ArrowPathIcon className="h-4 w-4 mr-1 inline-block fill-slate-900" />{" "}
                Swap <span className="text-green-500">origin</span> -{" "}
                <span className="text-red-500">destination</span>
              </button>
            )}
          <div
            className="absolute top-2 left-2 border-gray-300 bg-transparent"
            style={{ zIndex: 10000 }}
          >
            <CitySearch
              onSearchResultSelect={onSearchResultSelect}
              destinationChecked={destinationChecked}
              setDestinationChecked={setDestinationChecked}
            />
          </div>
          <Map
            cart={cart}
            result={selectedResult}
            originArea={originArea}
            destinationArea={destinationArea}
            destinationChecked={destinationChecked}
            onOriginAreaSelect={onOriginAreaSelect}
            onDestinationAreaSelect={onDestinationAreaSelect}
          />
        </div>
        <div className="col-span-6">
          <div className="grid grid-cols-1 text-left content-start">
            <Navbar
              query={query}
              onRunQuery={onRunQuery}
              onSaveQuery={onSaveQuery}
              restoreQueryOption={restoreQueryOption}
              sessionLocalQueries={sessionLocalQueries}
              setShowQuery={() => setShowQuery(!showQuery)}
              queryOption={queryOption}
              setQueryOption={setQueryOption}
              availableShippers={availableShippers}
              selectedShippers={selectedShippers}
              onSelectShippers={setSelectedShippers}
              inProgress={inProgress}
              runTime={runTime}
              backend={selectedBackend}
              queryType={queryType}
              queryTypes={queryTypes}
              setQueryType={setQueryType}
              balanced={balanced}
              onSetBalanced={setBalanced}
              minDistance={minDistance}
              maxDistance={maxDistance}
              onMinDistanceChange={setMinDistance}
              onMaxDistanceChange={setMaxDistance}
              minRPM={minRPM}
              maxRPM={maxRPM}
              onMinRPMChange={setMinRPM}
              onMaxRPMChange={setMaxRPM}
              minLoads={minLoads}
              maxLoads={maxLoads}
              onMinLoadsChange={setMinLoads}
              onMaxLoadsChange={setMaxLoads}
              selectedEquipmentClass={selectedEquipmentClass}
              equipmentClasses={equipmentClasses}
              onEquipmentClassChange={setSelectedEquipmentClass}
              executionStrategies={executionStrategies}
              selectedExecutionStrategy={selectedExecutionStrategy}
              onExecutionStrategyChange={setSelectedExecutionStrategy}
              recordTypeOptions={recordTypeOptions}
              selectedRecordTypes={selectedRecordTypes}
              onSelectRecordType={setSelectedRecordTypes}
            />
            {showQuery && (
              <div>
                <CodeMirror
                  value={query}
                  height="150px"
                  extensions={[javascript({ jsx: true })]}
                  onChange={onQueryChange}
                />
              </div>
            )}
            <div className="border-t border-green-900"></div>
            {error && <div className="text-red-500">{error.message}</div>}
            <Results
              results={results}
              orderBy={orderBy}
              orderByOptions={orderByOptions}
              onOrderResults={onOrderResults}
              showQuery={showQuery}
              setResult={onSetResult}
              onAddToCart={onAddToCart}
              backend={selectedBackend}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
