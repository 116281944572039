import { useState, useEffect } from 'react';
import NetworkMoveResult from './Result'
import DropdownOrderBy from './DropdownOrderBy';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid';

export default function Results(props) {
    const [start, setStart] = useState(0)
    const [slice, setSlice] = useState([]);
    const [height, setHeight] = useState("h-[calc(100vh-16rem)]")

    const pageSize = 10;
    const total = props.results !== undefined ? props.results.length : 0

    useEffect(() => {
        setStart(0)
        setSlice([...props.results.slice(start, start + pageSize)])
    }, [props.results])

    useEffect(() => {
        setSlice([...props.results.slice(start, start + pageSize)])
    }, [start])

    useEffect(() => {
        // setHeaderSize(props.showQuery ? 150 : 100)
        setHeight(props.showQuery ? "h-[calc(100vh-25rem)]" : "h-[calc(100vh-16rem)]")
    }, [props.showQuery])

    const nextPage = () => {
        if (start + pageSize < total) {
            setStart(start + pageSize)
        }
    }

    const prevPage = () => {
        if (start > 0) {
            setStart(Math.max(start - pageSize, 0))
        }
    }

    return <div className={`flex flex-col`}>
        {
            props.results && props.results.length > 0 &&
            <div className="flex items-center justify-between px-2 align-center border bg-slate-100 h-10">
                <div className="flex flex-row">
                    <a
                        href="#"
                        className={`relative mr-1 inline-flex items-center rounded-md border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 hover:bg-gray-50 ${total > pageSize && start > 0 ? 'enabled' : 'disabled'}`}
                        onClick={prevPage}
                        disabled
                    > Previous</a>
                    <a
                        href="#"
                        className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 hover:bg-gray-50 ${start + pageSize < total ? 'enabled' : 'disabled'}`}
                        onClick={nextPage}
                    >
                        Next
                    </a>
                </div>
                <p className="text-xs font-light text-gray-700 text-center">
                    Showing {start + 1} to {Math.min(total, start + pageSize)} of <span className="font-medium">{total}</span> results
                </p>
                <div className="inline-block inline-flex items-center">
                    <DropdownOrderBy name={props.orderBy ? props.orderBy.name : "Order by"} option={props.orderBy} options={props.orderByOptions} onClick={props.onOrderResults} />
                </div>
            </div>
        }
        <div className={`overflow-y-scroll ${height}`}>
            {
                slice.map((result, i) => {
                    return <div key={i} onClick={() => props.setResult(result)}>
                        <NetworkMoveResult result={result} onAddToCart={props.onAddToCart} backend={props.backend} />
                    </div>
                })
            }
        </div>
    </div >
}