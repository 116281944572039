import { Polygon, Polyline } from 'react-leaflet';
import { latLngToCell, gridDisk, cellsToMultiPolygon } from 'h3-js';

function getDiskPolygon(coordinates, resolution, k) {
    try {
        const index = latLngToCell(coordinates[0], coordinates[1], resolution || 5);
        return cellsToMultiPolygon(gridDisk(index, k || 3));
    } catch {
        return [];
    }
}

function Lane(props) {
    return <><Polyline
        key={props.id}
        pathOptions={{ color: props.color || "green", weight: 3, opacity: 0.5 }}
        positions={props.lane && props.lane.geometry ? props.lane.geometry.coordinates : []}
    />
        {props.isCartLane ? <></> :
            <>
                <Polygon key={1}
                    pathOptions={{ color: props.color || "green", weight: 2, opacity: 0.55 }}
                    positions={props.lane && props.lane.geometry ? getDiskPolygon(props.lane.geometry.coordinates[0]) : []} />
                <Polygon key={2}
                    pathOptions={{ color: props.color || "red", weight: 2, opacity: 0.95 }}
                    positions={props.lane && props.lane.geometry ? getDiskPolygon(props.lane.geometry.coordinates[props.lane.geometry.coordinates.length - 1]) : []} />
            </>
        }
    </>
}

export default Lane;
