import { cellToChildren } from "h3-js";
import dedent from "dedent";

const queries = [
  {
    name: "One-way",
    getQuery: function (
      shipperOptions,
      queryType,
      originArea,
      destinationArea,
      balanced,
      distance,
      rpm,
      loads,
      equipmentClass,
      backend,
      executionStrategy,
      selectedRecordTypes
    ) {
      const shipperIDs = `[${shipperOptions.map((option) => option.id)}]`;
      const recordTypes = `[${selectedRecordTypes.map(
        (option) => `"${option.name}"`
      )}]`;

      return dedent(`
            ${
              originArea && originArea.length
                ? dedent(`
                MATCH (o:Origin) 
                WHERE o.id in ['${originArea
                  .map((index) => cellToChildren(index, 5))
                  .flat()
                  .join("', '")}']
                WITH collect(o) as origins`)
                : ""
            }
            ${
              destinationArea && destinationArea.length
                ? dedent(`
                MATCH (d:Destination)
                WHERE d.id in ['${destinationArea
                  .map((index) => cellToChildren(index, 5))
                  .flat()
                  .join("', '")}']
                WITH ${
                  originArea && originArea.length ? "origins," : ""
                } collect(d) as destinations`)
                : ""
            }
            MATCH (o:Origin) <-- (l:Lane) --> (d:Destination)
            WHERE 
                l.owner in ${shipperIDs} 
                ${
                  backend === "Analytics"
                    ? `\nAND l.type in ${recordTypes}`
                    : ""
                }
                AND ${distance[0]} <= l.miles <= ${distance[1]}
                AND ${loads[0]} <= l.lane_annualized_total_ships <= ${loads[1]}
                ${
                  backend === "Analytics"
                    ? `AND ${rpm[0]} <= coalesce(l.mean_spend, 0) / (coalesce(l.miles, 0) + 1) <= ${rpm[1]}`
                    : ""
                }
                ${originArea && originArea.length ? `\nAND o in origins` : ""} 
                ${
                  destinationArea && destinationArea.length
                    ? `\nAND d in destinations`
                    : ""
                }
                ${
                  equipmentClass.name.startsWith("Dry")
                    ? `\nAND l.equipment_class = "DRY"`
                    : ""
                }
                ${
                  equipmentClass.name.startsWith("Reefer")
                    ? `\nAND l.equipment_class = "REEFER"`
                    : ""
                }
            RETURN 
                l as l0,
                l.miles as total_miles
            ;
            `).replaceAll(/\s*(\r?\n)\s*/g, "$1");
    },
  },
  {
    name: "Continuous move",
    getQuery: function (
      shipperOptions,
      queryType,
      originArea,
      destinationArea,
      balanced,
      distance,
      rpm,
      loads,
      equipmentClass,
      backend,
      executionStrategy,
      selectedRecordTypes
    ) {
      const shipperIDs = `[${shipperOptions.map((option) => option.id)}]`;
      const recordTypes = `[${selectedRecordTypes.map(
        (option) => `"${option.name}"`
      )}]`;
      return dedent(`
            ${
              originArea && originArea.length
                ? dedent(`MATCH (o:Origin) 
             WHERE o.id in ['${originArea
               .map((index) => cellToChildren(index, 5))
               .flat()
               .join("', '")}']
             WITH collect(o) as origins`)
                : ""
            }
            ${
              destinationArea && destinationArea.length
                ? dedent(`
            MATCH (d:Destination)
            WHERE d.id in ['${destinationArea
              .map((index) => cellToChildren(index, 5))
              .flat()
              .join("', '")}']
            WITH ${
              originArea && originArea.length ? "origins, " : " "
            }collect(d) as destinations`)
                : ""
            }
            MATCH path = (
                (o0:Origin) <- [:STARTS_AT] - (l0:Lane) - [:ENDS_AT] ->
                (d0:Destination) - [dead0:SHORT_DEADHEADS_TO] ->
                (o1:Origin) <- [:STARTS_AT] - (l1:Lane) - [:ENDS_AT] ->
                (d1:Destination)
            )
            WHERE l0.owner in ${shipperIDs}
            AND ${
              queryType.name === "Internal" ? "l1.owner in" : "not l1.owner in"
            } ${shipperIDs}
            AND 100 <= l0.miles <= 3000
            AND 100 <= l1.miles <= 3000
            ${
              backend === "Analytics"
                ? `\nAND l0.type in ${recordTypes} AND l1.type in ${recordTypes}`
                : ""
            }
            AND ${loads[0]} <= l0.lane_annualized_total_ships <= ${loads[1]}
            AND ${loads[0]} <= l1.lane_annualized_total_ships <= ${loads[1]}
            ${
              backend === "Analytics"
                ? `AND ${rpm[0]} <= coalesce(l0.mean_spend, 0) / (coalesce(l0.miles, 0) + 1) <= ${rpm[1]}`
                : ""
            }
            ${
              backend === "Analytics"
                ? `AND ${rpm[0]} <= coalesce(l1.mean_spend, 0) / (coalesce(l1.miles, 0) + 1) <= ${rpm[1]}`
                : ""
            }
            ${
              equipmentClass.name === "Dry"
                ? `\nAND l0.equipment_class = "DRY" \nAND l1.equipment_class = "DRY"`
                : ""
            }
              ${
                equipmentClass.name === "Reefer"
                  ? `\nAND l0.equipment_class = "REEFER" \nAND l1.equipment_class = "REEFER"`
                  : ""
              }
              ${
                equipmentClass.name === "DryReefer"
                  ? `\nAND l0.equipment_class = "DRY" \nAND l1.equipment_class = "REEFER"`
                  : ""
              }
              ${
                equipmentClass.name === "ReeferDry"
                  ? `\nAND l0.equipment_class = "REEFER" \nAND l1.equipment_class = "DRY"`
                  : ""
              }
            AND l0.last_spend_date >= date("2022-05-21")
            AND l1.last_spend_date >= date("2022-05-21")
            AND not exists((d1) - [:SHORT_DEADHEADS_TO] -> (o0))
            ${originArea && originArea.length ? "AND o0 in origins" : ""}
            ${
              destinationArea && destinationArea.length
                ? `\nAND d1 in destinations`
                : ""
            }
            ${
              backend === "Execution" && executionStrategy === "Level 1"
                ? `AND l0.destination_appointment_date <= l1.origin_appointment_date`
                : ""
            }
            ${
              backend === "Execution" && executionStrategy === "Level 2"
                ? `AND l1.origin_appointment_datetime - duration('P0DT8H') <= l0.destination_appointment_datetime + duration('P0DT' + toString(dead0.miles / 55) + 'H')  <= l1.origin_appointment_datetime + duration('P0DT8H')`
                : ""
            }
            WITH o0, d0, o1, d1, l0, l1,
                l0.lane_annualized_total_ships + l1.lane_annualized_total_ships as total_loads,
                l0.miles + l1.miles as total_miles,
                dead0.miles as total_deadhead,
                (l0.mean_spend + l1.mean_spend) / 2 as mean_spend_per_lane,
                abs(l0.lane_annualized_total_ships - l1.lane_annualized_total_ships) as load_imbalance
            WHERE ${distance[0]} <= total_miles <= ${distance[1]}
              AND total_deadhead <= 0.05 * total_miles
              ${balanced ? "AND load_imbalance <= 0.1 * total_loads" : ""}
            RETURN
                l0,
                l1,
                total_miles,
                total_deadhead
            ;
          `).replaceAll(/\s*(\r?\n)\s*/g, "$1");
    },
  },
  {
    name: "Continuous move (3-legs)",
    getQuery: function (
      shipperOptions,
      queryType,
      originArea,
      destinationArea,
      balanced,
      distance,
      rpm,
      loads,
      equipmentClass,
      backend,
      executionStrategy,
      selectedRecordTypes
    ) {
      const shipperIDs = `[${shipperOptions.map((option) => option.id)}]`;
      const recordTypes = `[${selectedRecordTypes.map(
        (option) => `"${option.name}"`
      )}]`;
      return dedent(`
            ${
              originArea && originArea.length
                ? dedent(`MATCH (o:Origin) 
             WHERE o.id in ['${originArea
               .map((index) => cellToChildren(index, 5))
               .flat()
               .join("', '")}']
             WITH collect(o) as origins`)
                : ""
            }
            ${
              destinationArea && destinationArea.length
                ? dedent(`
            MATCH (d:Destination)
            WHERE d.id in ['${destinationArea
              .map((index) => cellToChildren(index, 5))
              .flat()
              .join("', '")}']
            WITH ${
              originArea && originArea.length ? "origins, " : " "
            }collect(d) as destinations`)
                : ""
            }
            MATCH path = (
                (o0:Origin) <- [:STARTS_AT] - (l0:Lane) - [:ENDS_AT] ->
                (d0:Destination) - [dead0:SHORT_DEADHEADS_TO] ->
                (o1:Origin) <- [:STARTS_AT] - (l1:Lane) - [:ENDS_AT] ->
                (d1:Destination) - [dead1:SHORT_DEADHEADS_TO] ->
                (o2:Origin) <- [:STARTS_AT] - (l2:Lane) - [:ENDS_AT] -> 
                (d2:Destination)
            )
            WHERE l0.owner in ${shipperIDs}
            AND ${
              queryType.name === "Internal" ? "l1.owner in" : "not l1.owner in"
            } ${shipperIDs}
            AND ${
              queryType.name === "Internal" ? "l2.owner in" : "not l2.owner in"
            } ${shipperIDs}
            AND 100 <= l0.miles <= 3000
            AND 100 <= l1.miles <= 3000
            AND 100 <= l2.miles <= 3000
            ${
              backend === "Analytics"
                ? `\nAND l0.type in ${recordTypes} AND l1.type in ${recordTypes} AND l2.type in ${recordTypes}`
                : ""
            }
            AND ${loads[0]} <= l0.lane_annualized_total_ships <= ${loads[1]}
            AND ${loads[0]} <= l1.lane_annualized_total_ships <= ${loads[1]}
            AND ${loads[0]} <= l2.lane_annualized_total_ships <= ${loads[1]}
            ${
              backend === "Analytics"
                ? `AND ${rpm[0]} <= coalesce(l0.mean_spend, 0) / (coalesce(l0.miles, 0) + 1) <= ${rpm[1]}`
                : ""
            }
            ${
              backend === "Analytics"
                ? `AND ${rpm[0]} <= coalesce(l1.mean_spend, 0) / (coalesce(l1.miles, 0) + 1) <= ${rpm[1]}`
                : ""
            }
            ${
              backend === "Analytics"
                ? `AND ${rpm[0]} <= coalesce(l2.mean_spend, 0) / (coalesce(l2.miles, 0) + 1) <= ${rpm[1]}`
                : ""
            }
            ${
              equipmentClass.name === "Dry"
                ? `\nAND l0.equipment_class = "DRY" \nAND l1.equipment_class = "DRY" \nAND l2.equipment_class = "DRY"`
                : ""
            }
            ${
              equipmentClass.name === "Reefer"
                ? `\nAND l0.equipment_class = "REEFER" \nAND l1.equipment_class = "REEFER" \nAND l2.equipment_class = "REEFER"`
                : ""
            }
            ${
              equipmentClass.name === "DryReefer"
                ? `\nAND l0.equipment_class = "DRY" \nAND l1.equipment_class = "REEFER" \nAND l2.equipment_class = "REEFER"`
                : ""
            }
            ${
              equipmentClass.name === "ReeferDry"
                ? `\nAND l0.equipment_class = "REEFER" \nAND l1.equipment_class = "DRY" \nAND l2.equipment_class = "DRY"`
                : ""
            }
            AND l0.last_spend_date >= date("2022-05-21")
            AND l1.last_spend_date >= date("2022-05-21")
            AND l2.last_spend_date >= date("2022-05-21")
            AND not exists((d1) - [:SHORT_DEADHEADS_TO] -> (o0))
            AND not exists((d2) - [:SHORT_DEADHEADS_TO] -> (o1))
            AND not exists((d2) - [:SHORT_DEADHEADS_TO] -> (o0))
            ${originArea && originArea.length ? "AND o0 in origins" : ""}
            ${
              destinationArea && destinationArea.length
                ? `\nAND d2 in destinations`
                : ""
            }
            ${
              backend === "Execution" && executionStrategy === "Level 1"
                ? `AND l0.destination_appointment_date <= l1.origin_appointment_date`
                : ""
            }
            ${
              backend === "Execution" && executionStrategy === "Level 1"
                ? `AND l1.destination_appointment_date <= l2.origin_appointment_date`
                : ""
            }
            ${
              backend === "Execution" && executionStrategy === "Level 2"
                ? `AND l1.origin_appointment_datetime - duration('P0DT8H') <= l0.destination_appointment_datetime + duration('P0DT' + toString(dead0.miles / 55) + 'H')  <= l1.origin_appointment_datetime + duration('P0DT8H')`
                : ""
            }
            ${
              backend === "Execution" && executionStrategy === "Level 2"
                ? `AND l2.origin_appointment_datetime - duration('P0DT8H') <= l1.destination_appointment_datetime + duration('P0DT' + toString(dead1.miles / 55) + 'H')  <= l2.origin_appointment_datetime + duration('P0DT8H')`
                : ""
            }
            WITH o0, d0, o1, d1, l0, l1, l2,
                l0.lane_annualized_total_ships + l1.lane_annualized_total_ships + l2.lane_annualized_total_ships as total_loads,
                l0.miles + l1.miles + l2.miles as total_miles,
                dead0.miles + dead1.miles as total_deadhead,
                (l0.mean_spend + l1.mean_spend + l2.mean_spend) / 3 as mean_spend_per_lane,
                abs(l0.lane_annualized_total_ships - l1.lane_annualized_total_ships) as l01_load_imbalance,
                abs(l1.lane_annualized_total_ships - l2.lane_annualized_total_ships) as l12_load_imbalance
            WHERE ${distance[0]} <= total_miles <= ${distance[1]}
              AND total_deadhead <= 0.05 * total_miles
              ${
                balanced
                  ? "AND l01_load_imbalance <= 0.1 * total_loads AND l12_load_imbalance <= 0.1 * total_loads"
                  : ""
              }
            RETURN
                l0,
                l1,
                l2,
                total_miles,
                total_deadhead
            ;
          `).replaceAll(/\s*(\r?\n)\s*/g, "$1");
    },
  },
  {
    name: "Round-trip",
    getQuery: function (
      shipperOptions,
      queryType,
      originArea,
      destinationArea,
      balanced,
      distance,
      rpm,
      loads,
      equipmentClass,
      backend,
      executionStrategy,
      selectedRecordTypes
    ) {
      const shipperIDs = `[${shipperOptions.map((option) => option.id)}]`;
      const recordTypes = `[${selectedRecordTypes.map(
        (option) => `"${option.name}"`
      )}]`;

      return dedent(`
            ${
              originArea && originArea.length
                ? dedent(`MATCH (o:Origin) 
                 WHERE o.id in ['${originArea
                   .map((index) => cellToChildren(index, 5))
                   .flat()
                   .join("', '")}']
                 WITH collect(o) as origins`)
                : ""
            }
            ${
              destinationArea && destinationArea.length
                ? dedent(`
                MATCH (d:Destination)
                WHERE d.id in ['${destinationArea
                  .map((index) => cellToChildren(index, 5))
                  .flat()
                  .join("', '")}']
                WITH ${
                  originArea && originArea.length ? "origins, " : " "
                }collect(d) as destinations`)
                : ""
            }
            MATCH path = (
                (o0:Origin) <- [:STARTS_AT] - (l0:Lane) - [:ENDS_AT] ->
                (d0:Destination) - [dead0:SHORT_DEADHEADS_TO|LONG_DEADHEADS_TO] ->
                (o1:Origin) <- [:STARTS_AT] - (l1:Lane) - [:ENDS_AT] ->
                (d1:Destination) - [dead1:SHORT_DEADHEADS_TO] ->
                (o0)
            )
            WHERE ${originArea && originArea.length ? `o0 in origins AND` : ""}
                ${
                  destinationArea && destinationArea.length
                    ? `d0 in destinations AND`
                    : ""
                }
                l0.owner in ${shipperIDs}
              AND ${
                queryType.name === "Internal"
                  ? "l1.owner in"
                  : "not l1.owner in"
              } ${shipperIDs}
              AND 100 <= l0.miles <= 3000
              AND 100 <= l1.miles <= 3000
              ${
                backend === "Analytics"
                  ? `\nAND l0.type in ${recordTypes} AND l1.type in ${recordTypes}`
                  : ""
              }
              AND ${loads[0]} <= l0.lane_annualized_total_ships <= ${loads[1]}
              AND ${loads[0]} <= l1.lane_annualized_total_ships <= ${loads[1]}
              ${
                backend === "Analytics"
                  ? `AND ${rpm[0]} <= coalesce(l0.mean_spend, 0) / (coalesce(l0.miles, 0) + 1) <= ${rpm[1]}`
                  : ""
              }
              ${
                backend === "Analytics"
                  ? `AND ${rpm[0]} <= coalesce(l1.mean_spend, 0) / (coalesce(l1.miles, 0) + 1) <= ${rpm[1]}`
                  : ""
              }
              ${
                equipmentClass.name === "Dry"
                  ? `\nAND l0.equipment_class = "DRY" \nAND l1.equipment_class = "DRY"`
                  : ""
              }
              ${
                equipmentClass.name === "Reefer"
                  ? `\nAND l0.equipment_class = "REEFER" \nAND l1.equipment_class = "REEFER"`
                  : ""
              }
              ${
                equipmentClass.name === "DryReefer"
                  ? `\nAND l0.equipment_class = "DRY" \nAND l1.equipment_class = "REEFER"`
                  : ""
              }
              ${
                equipmentClass.name === "ReeferDry"
                  ? `\nAND l0.equipment_class = "REEFER" \nAND l1.equipment_class = "DRY"`
                  : ""
              }
              AND l0.last_spend_date >= date("2022-05-16")
              AND l1.last_spend_date >= date("2022-05-16")
              ${
                backend === "Execution" && executionStrategy === "Level 1"
                  ? `AND l0.destination_appointment_date <= l1.origin_appointment_date`
                  : ""
              }
              ${
                backend === "Execution" && executionStrategy === "Level 2"
                  ? `AND l1.origin_appointment_datetime - duration('P0DT8H') <= l0.destination_appointment_datetime + duration('P0DT' + toString(dead0.miles / 55) + 'H')  <= l1.origin_appointment_datetime + duration('P0DT8H')`
                  : ""
              }
            WITH
                l0, l1, dead0, dead1,
                l0.miles + l1.miles as total_miles,
                l0.lane_annualized_total_ships + l1.lane_annualized_total_ships as total_loads,
                dead0.miles + dead1.miles as total_deadhead,
                (l0.mean_spend + l1.mean_spend) / 2 as mean_spend_per_lane,
                abs(l0.lane_annualized_total_ships - l1.lane_annualized_total_ships) as load_imbalance
            WHERE ${distance[0]} <= total_miles <= ${distance[1]}
              AND total_deadhead <= 0.1 * total_miles
              ${balanced ? "AND load_imbalance <= 0.1 * total_loads" : ""}
            RETURN
                l0,
                l1,
                total_miles,
                total_deadhead
            ;
        `).replaceAll(/\s*(\r?\n)\s*/g, "$1");
    },
  },
  {
    name: "Triangular circuit",
    getQuery: function (
      shipperOptions,
      queryType,
      originArea,
      destinationArea,
      balanced,
      distance,
      rpm,
      loads,
      equipmentClass,
      backend,
      executionStrategy,
      selectedRecordTypes
    ) {
      const shipperIDs = `[${shipperOptions.map((option) => option.id)}]`;
      const recordTypes = `[${selectedRecordTypes.map(
        (option) => `"${option.name}"`
      )}]`;
      return dedent(`
            ${
              originArea && originArea.length
                ? dedent(`MATCH (o:Origin) 
                 WHERE o.id in ['${originArea
                   .map((index) => cellToChildren(index, 5))
                   .flat()
                   .join("', '")}']
                 WITH collect(o) as origins`)
                : ""
            }
            ${
              destinationArea && destinationArea.length
                ? dedent(`
                MATCH (d:Destination)
                WHERE d.id in ['${destinationArea
                  .map((index) => cellToChildren(index, 5))
                  .flat()
                  .join("', '")}']
                WITH ${
                  originArea && originArea.length ? "origins, " : " "
                }collect(d) as destinations`)
                : ""
            }
                MATCH path = (
                    (o0:Origin) <- [:STARTS_AT] - (l0:Lane) - [:ENDS_AT] ->
                    (d0:Destination) - [dead0:SHORT_DEADHEADS_TO|LONG_DEADHEADS_TO] ->
                    (o1:Origin) <- [:STARTS_AT] - (l1:Lane) - [:ENDS_AT] ->
                    (d1:Destination) - [dead1:SHORT_DEADHEADS_TO|LONG_DEADHEADS_TO] ->
                    (o2:Origin) <- [:STARTS_AT] - (l2:Lane) - [:ENDS_AT] ->
                    (d2:Destination) - [dead2:SHORT_DEADHEADS_TO] ->
                    (o0)
                )
                WHERE l0.owner in ${shipperIDs}
                AND ${
                  queryType.name === "Internal"
                    ? "l1.owner in"
                    : "not l1.owner in"
                } ${shipperIDs}
                AND ${
                  queryType.name === "Internal"
                    ? "l2.owner in"
                    : "not l2.owner in"
                } ${shipperIDs}
                AND 100 <= l0.miles <= 3000
                AND 100 <= l1.miles <= 3000
                AND 100 <= l2.miles <= 3000
                ${
                  backend === "Analytics"
                    ? `\nAND l0.type in ${recordTypes} AND l1.type in ${recordTypes} AND l2.type in ${recordTypes}`
                    : ""
                }
                AND ${loads[0]} <= l0.lane_annualized_total_ships <= ${loads[1]}
                AND ${loads[0]} <= l1.lane_annualized_total_ships <= ${loads[1]}
                AND ${loads[0]} <= l2.lane_annualized_total_ships <= ${loads[1]}
                ${
                  backend === "Analytics"
                    ? `AND ${rpm[0]} <= coalesce(l0.mean_spend, 0) / (coalesce(l0.miles, 0) + 1) <= ${rpm[1]}`
                    : ""
                }
                ${
                  backend === "Analytics"
                    ? `AND ${rpm[0]} <= coalesce(l1.mean_spend, 0) / (coalesce(l1.miles, 0) + 1) <= ${rpm[1]}`
                    : ""
                }
                ${
                  backend === "Analytics"
                    ? `AND ${rpm[0]} <= coalesce(l2.mean_spend, 0) / (coalesce(l2.miles, 0) + 1) <= ${rpm[1]}`
                    : ""
                }
                ${
                  equipmentClass.name === "Dry"
                    ? `\nAND l0.equipment_class = "DRY" \nAND l1.equipment_class = "DRY" \nAND l2.equipment_class = "DRY"`
                    : ""
                }
                ${
                  equipmentClass.name === "Reefer"
                    ? `\nAND l0.equipment_class = "REEFER" \nAND l1.equipment_class = "REEFER" \nAND l2.equipment_class = "REEFER"`
                    : ""
                }
                ${
                  equipmentClass.name === "DryReefer"
                    ? `\nAND l0.equipment_class = "DRY" \nAND l1.equipment_class = "REEFER" \nAND l2.equipment_class = "REEFER"`
                    : ""
                }
                ${
                  equipmentClass.name === "ReeferDry"
                    ? `\nAND l0.equipment_class = "REEFER" \nAND l1.equipment_class = "DRY" \nAND l2.equipment_class = "DRY"`
                    : ""
                }
                AND l0.last_spend_date >= date("2022-05-21")
                AND l1.last_spend_date >= date("2022-05-21")
                AND l2.last_spend_date >= date("2022-05-21")
                ${originArea && originArea.length ? "AND o0 in origins" : ""}
                ${
                  destinationArea && destinationArea.length
                    ? `AND d0 in destinations`
                    : ""
                }
                ${
                  backend === "Execution" && executionStrategy === "Level 1"
                    ? `AND l0.destination_appointment_date <= l1.origin_appointment_date`
                    : ""
                }
                ${
                  backend === "Execution" && executionStrategy === "Level 1"
                    ? `AND l1.destination_appointment_date <= l2.origin_appointment_date`
                    : ""
                }
                ${
                  backend === "Execution" && executionStrategy === "Level 2"
                    ? `AND l1.origin_appointment_datetime - duration('P0DT8H') <= l0.destination_appointment_datetime + duration('P0DT' + toString(dead0.miles / 55) + 'H')  <= l1.origin_appointment_datetime + duration('P0DT8H')`
                    : ""
                }
                ${
                  backend === "Execution" && executionStrategy === "Level 2"
                    ? `AND l2.origin_appointment_datetime - duration('P0DT8H') <= l1.destination_appointment_datetime + duration('P0DT' + toString(dead1.miles / 55) + 'H')  <= l2.origin_appointment_datetime + duration('P0DT8H')`
                    : ""
                }
                WITH
                    l0,
                    l1,
                    l2,
                    dead0,
                    dead1,
                    dead2,
                    l0.lane_annualized_total_ships + l1.lane_annualized_total_ships + l2.lane_annualized_total_ships as total_loads,
                    l0.miles + l1.miles + l2.miles as total_miles,
                    dead0.miles + dead1.miles + dead2.miles as total_deadhead,
                    (l0.mean_spend + l1.mean_spend + l2.mean_spend) / 3 as mean_spend_per_lane,
                    abs(l0.lane_annualized_total_ships - l1.lane_annualized_total_ships) as l01_load_imbalance,
                    abs(l1.lane_annualized_total_ships - l2.lane_annualized_total_ships) as l12_load_imbalance
                WHERE ${distance[0]} <= total_miles <= ${distance[1]}
                  AND total_deadhead <= 0.1 * total_miles
                ${
                  balanced
                    ? "AND l01_load_imbalance <= 0.1 * total_loads AND l12_load_imbalance <= 0.1 * total_loads"
                    : ""
                }
                RETURN
                    l0,
                    l1,
                    l2,
                    total_miles,
                    total_deadhead
                ;
                  
        `).replaceAll(/\s*(\r?\n)\s*/g, "$1");
    },
  },
];

export default queries;
