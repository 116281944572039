import { ArrowRightIcon, PlusCircleIcon, ClipboardDocumentIcon } from "@heroicons/react/20/solid";
import React, { useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import useState from "react-usestateref";
import Moment from "moment";
import { createToast } from "react-simple-toasts";

const customToast = createToast({
    duration: 1000,
    theme: "dark",
    className: "custom-toast",
    clickClosable: true,
    position: "bottom-right",
    maxVisibleToasts: 1,
    render: (message) => <b className="my-toast">{message}</b>,
  });
  

function LaneResult(props) {
  const [lane, setLane] = useState(props.lane);

  useDeepCompareEffect(() => {
    setLane(JSON.parse(JSON.stringify(props.lane)));
  }, [props.lane]);

  return (
    <tr className="font-medium text-[10px] max-w-full">
      <td>
        <span style={{ color: lane.color }} className="truncate block w-20">
          {lane && lane.owner_name ? lane.owner_name : ""}
        </span>
      </td>
      <td>
        <span style={{ color: lane.color }} className="truncate block w-16">
          {lane && lane.equipment_class ? lane.type : ""}
        </span>
      </td>
      <td>
        <span style={{ color: lane.color }} className="truncate block w-16">
          {lane && lane.equipment_class ? lane.equipment_class : ""}
        </span>
      </td>
      <td>
        <span style={{ color: lane.color }} className="truncate block w-28">
          {lane && lane.o_name ? lane.o_name : "NO NAME"}{" "}
        </span>
      </td>
      <td>
        <span style={{ color: lane.color }} className="truncate block w-28">
          {lane && lane.d_name ? lane.d_name : "NO NAME"}
        </span>
      </td>
      <td className="text-right">
        <span>{lane.miles.toFixed(0)} </span>
      </td>
      {props.backend && props.backend.name == "Analytics" && (
        <td className="text-right">
          <span>{lane.lane_annualized_total_ships} </span>
        </td>
      )}
      {props.backend && props.backend.name == "Analytics" && lane.mean_spend ? (
        <td className="text-right">
          <span>{lane.mean_spend.toFixed(1)} </span>
        </td>
      ) : (
        <></>
      )}
      {props.backend &&
      props.backend.name == "Analytics" &&
      lane.mean_spend &&
      lane.miles ? (
        <td className="text-right">
          <span>{(lane.mean_spend / lane.miles).toFixed(1)} </span>
        </td>
      ) : (
        <></>
      )}
      {props.backend &&
      props.backend.name == "Execution" &&
      lane.origin_appointment_datetime ? (
        <td className="text-right">
          <span>
            {Moment(lane.origin_appointment_datetime).format("Do MMM Y H:mm A")}{" "}
          </span>
        </td>
      ) : (
        <></>
      )}
      {props.backend &&
      props.backend.name == "Execution" &&
      lane.destination_appointment_datetime ? (
        <td className="text-right">
          <span>
            {Moment(lane.destination_appointment_datetime).format(
              "Do MMM Y H:mm A"
            )}{" "}
          </span>
        </td>
      ) : (
        <></>
      )}
    </tr>
  );
}

function NetworkMoveResult(props) {
  const [lanes, setLanes] = useState([]);
  useEffect(() => {
    setLanes([...props.result.lanes]);
  }, [props.result]);

  const addToCart = function (e) {
    e.stopPropagation();
    props.onAddToCart(props.result);
  };

  return (
    <div
      className={`hover:bg-gray-200 m-2 text-gray-800 font-semibold px-4 py-2 relative group ${
        props.result.selected ? "bg-gray-200" : "bg-white"
      }`}
    >
      <button
        className="group-hover:visible hover:bg-sky-400 p-1 text-right bg-sky-200 absolute invisible top-1 right-28 text-sm"
        onClick={() => {
            navigator.clipboard.writeText(props.result.lanes.map((item) => item.id).join(","));
            customToast("Lane ID(s) copied to clipboard.");
          }}
      > 
        <span className="text-xs">Copy lane ID(s)</span>
      </button>
      <button
        className="group-hover:visible hover:bg-sky-400 p-1 text-right bg-sky-200 absolute invisible top-1 right-1 text-sm"
        onClick={addToCart}
      >
        <PlusCircleIcon className="h-4 w-4 inline-block" />
        <span className="pt-1.5 pl-1">Add to cart</span>
      </button>
      <div className="flex justify-start xl:w-2/3">
        <div className="inline-block w-44 flex-auto">
          {" "}
          <span className="text-green-700 truncate block">
            {lanes && lanes.length && lanes[0].o_name ? lanes[0].o_name : ""}
          </span>
        </div>
        <div className="inline-block flex-auto">
          <ArrowRightIcon className="h-4 w-4 inline text-gray-400" />
        </div>
        <div className="inline-block flex-auto w-44 ml-4">
          {" "}
          <span className="text-red-700 truncate block">
            {lanes && lanes.length && lanes[lanes.length - 1].d_name
              ? lanes[lanes.length - 1].d_name
              : ""}
          </span>
        </div>
      </div>
      <div className="font-light text-xs">
        <span>
          Approximate total distance: {props.result.total_miles} mi (
          {lanes.length} {lanes.length > 1 ? "legs" : "leg"})
        </span>
        <br />
        {props.result.total_deadhead && (
          <span>
            Approximate total deadhead: {props.result.total_deadhead} mi
          </span>
        )}
      </div>
      {lanes && lanes.length ? (
        <div className="mt-2">
          <table className="table-auto">
            <thead>
              <tr>
                <th className="font-medium text-[8px] leading-tight align-bottom text-gray-500 w-30">
                  Shipper
                </th>
                <th className="font-medium text-[8px] leading-tight align-bottom text-gray-500 w-30">
                  Type
                </th>
                <th className="font-medium text-[8px] leading-tight align-bottom text-gray-500 w-30">
                  Equipment
                </th>
                <th className="font-medium text-[8px] leading-tight align-bottom text-gray-500 w-30">
                  Origin
                </th>
                <th className="font-medium text-[8px] leading-tight align-bottom text-gray-500 w-30">
                  Destination
                </th>
                <th className="font-medium text-[8px] text-right leading-tight align-bottom text-gray-500 w-30">
                  Miles
                </th>
                {props.backend && props.backend.name == "Analytics" && (
                  <th className="font-medium text-[8px] text-right leading-tight text-gray-500 w-30">
                    Annualized loads
                  </th>
                )}
                <th className="font-medium text-[8px] text-right leading-tight text-gray-500 w-30">
                  {props.backend && props.backend.name == "Execution"
                    ? "Pickup"
                    : "Mean spend"}
                </th>
                <th className="font-medium text-[8px] text-right leading-tight text-gray-500 w-30">
                  {props.backend && props.backend.name == "Execution"
                    ? "Delivery"
                    : "Mean RPM"}
                </th>
              </tr>
            </thead>
            <tbody>
              {props.result.lanes.map((lane, i) => (
                <LaneResult key={i} lane={lane} backend={props.backend} />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default NetworkMoveResult;
