import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default function DropdownDistance(props) {
    const onMinChange = (e) => {
        props.onMinDistanceChange(e.target.value);
    }

    const onMaxChange = (e) => {
        props.onMaxDistanceChange(e.target.value);
    }


    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 px-2 text-sm font-medium text-gray-700 hover:bg-gray-100">
                    <span className="truncate w-16 text-left">{props.name}</span>
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute max-h-64 right-0 z-10 w-64 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ zIndex: 10000 }}>
                    <div className="flex flex-col m-5 text-sm font-light">
                        <label htmlFor="min-small-range-distance" className="block text-sm mb-1 font-light text-gray-900">Minimum total miles: {props.minDistance}</label>
                        <input id="min-small-range-distance" type="range" min="0" max="5000" value={props.minDistance} step="100" className="w-full h-1 mb-6 bg-gray-200 rounded-lg appearance-none cursor-pointer range-sm dark:bg-gray-700" onChange={onMinChange} />
                        <label htmlFor="min-small-range-distance" className="block text-sm mb-1 font-light text-gray-900">Maximum total miles: {props.maxDistance}</label>
                        <input id="min-small-range-distance" type="range" min="0" max="5000" value={props.maxDistance} step="100" className="w-full h-1 mb-6 bg-gray-200 rounded-lg appearance-none cursor-pointer range-sm dark:bg-gray-700" onChange={onMaxChange} />
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}