import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState } from 'react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DropdownQueries(props) {
    const [localQueries, setLocalQueries] = useState([]);

    const deleteLocalQuery = (e, option) => {
        e.stopPropagation();
        const index = localQueries.findIndex((x) => x.name === option.name);
        if (index > -1) {
            localQueries.splice(index, 1);
            setLocalQueries([...localQueries]);
            localStorage.setItem('localQueries', JSON.stringify(localQueries));
        }
    }

    useEffect(() => {
        async function init() {
            let data = await localStorage.getItem('localQueries') || '[]';
            data = JSON.parse(data);
            
            for (const element of data) {
                element.getQuery = () => element.query
            }
            setLocalQueries(data);
        }
        init();
    }, [props.sessionLocalQueries])


    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 px-2 text-sm font-medium text-gray-700 hover:bg-gray-100">
                    <span className="truncate text-left">{props.name}</span>
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute max-h-64 left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 overflow-y-scroll ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">{
                        props.options.map((option, i) => <Menu.Item key={i} onClick={() => { props.onClick(option) }}>
                            {({ active }) => (
                                <a
                                    href="#"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm'
                                    )}
                                >
                                    {option.displayName || option.name} {option.id !== undefined ? `(${option.id})` : ''}
                                </a>
                            )}
                        </Menu.Item>
                        )
                    }
                    </div>
                    {localQueries && localQueries.length > 0 &&
                        (<><span className="font-medium text-sm ml-2 underline">Saved queries  ({localQueries.length}):</span>
                            <div className="py-1">{
                                localQueries.map((option, i) => <Menu.Item key={i} onClick={() => { props.restoreQueryOption(option) }}>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm justify-between flex'
                                            )}
                                        >
                                            <span>{option.displayName || option.name} {option.id !== undefined ? `(${option.id})` : ''}</span>
                                            <button onClick={(e) => deleteLocalQuery(e, option)} className="align-middle"><XCircleIcon className="h-4 w-4 fill-slate-700"></XCircleIcon></button>
                                        </a>
                                        
                                    )}
                                </Menu.Item>
                                )
                            }
                            </div></>)
                    }
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
