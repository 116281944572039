import { ArrowDownTrayIcon, FireIcon, MapIcon, PlusCircleIcon, XCircleIcon, InformationCircleIcon } from "@heroicons/react/20/solid";

const howDoIFilterByLocationMessage = `
Hold the Control key (Mac users: Control, not Command), click and drag across the map to select an area. Your selection will be used to show only results (lanes or network moves) that start from the selected area.
`

export default function Navbar(props) {

    return <div className="grid grid-cols-1 w-screen text-left h-16 bg-sky-100 border border-b-10 border-black">
        <div className="m-1 flex flex-row justify-between align-center">
            <div className="flex flew-row items-center">
                <span className="relative inline-block text-left mx-2">Data source:</span>
                {props.backends.map((backend, i) => <div key={i}>
                    <div className="flex items-center p-2 border border-gray-200 rounded dark:border-gray-700 mr-1">
                        <input id="bordered-radio-1" type="radio" checked={backend.name === props.selectedBackend.name} value={backend.name} name="bordered-radio" className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={(e) => { props.onBackendChange(backend) }} />
                        <label htmlFor="bordered-radio-1" className="w-full ml-1 text-sm font-light">{backend.displayName}</label>
                    </div>
                </div>)}
            </div>
            <div className="mb-2 flex flex-row">
                {props.results && props.results.length > 0 ?
                    <button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 px-2 text-sm font-medium text-gray-700 hover:bg-gray-100"
                        onClick={props.onAddAllToCart}>
                        <PlusCircleIcon className="h-4 w-4 fill-sky-500 inline-block mr-1" />{" "}
                        <span className="text-sky-500 font-medium">Add all to cart</span>
                    </button> : <></>
                }
                {props.cart && props.cart.length ?
                    <>
                        <button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 px-2 text-sm font-medium text-gray-700 hover:bg-gray-100"
                            onClick={props.onDownloadCart}>
                            <ArrowDownTrayIcon className="h-4 w-4 fill-green-900 inline-block mr-1" />{" "}
                            <span>Download cart ({props.cart.length})</span>

                        </button>
                        <button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 px-2 text-sm font-medium text-gray-700 hover:bg-gray-100"
                            onClick={props.onShowCartOnMap}>
                            <MapIcon className="h-4 w-4 fill-green-600 inline-block mr-1" />{" "}
                            <span className="text-green-600 font-medium">Show cart on map</span>

                        </button>
                        <button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 px-2 text-sm font-medium text-gray-700 hover:bg-gray-100"
                            onClick={props.onClearCart}>
                            <FireIcon className="h-4 w-4 fill-red-500 inline-block mr-1" />{" "}
                            <span className="text-red-500 font-medium">Clear cart</span>

                        </button>
                    </> : <></>
                }
            </div>
        </div>
    </div>
}