import Wkt from "wicket";
import dedent from "dedent";

const graphEndpoint = "https://api.leaflogistics.com/graph-explorer";
const companyNames = {
  58921: { name: "Aarons" },
  49665: { name: "Campbell Soup Company" },
  51405: { name: "Corteva, INC" },
  51408: { name: "Coca Cola Beverages Northeast" },
  51407: { name: "Synchrogistics" },
  53640: { name: "CHEP USA" },
  53647: { name: "Niagara Bottling" },
  56611: { name: "Colson North America " },
  60: { name: "Demo Shipper" },
  56612: { name: "Party City" },
  53641: { name: "Church & Dwight" },
  53644: { name: "NextEra Energy" },
  53646: { name: "Test Shipper" },
  56615: { name: "Sea World " },
  56616: { name: "Johnson & Johnson Medtech" },
  49663: { name: "Yanmar" },
  49664: { name: "Standard Textiles" },
  58924: { name: "Constellation Brands" },
  60580: { name: "BodyArmor" },
  100000: { name: "Premium Waters Inc" },
  272: { name: "Stefan's Test" },
  239: { name: "Atlantic Packaging" },
  305: { name: "Home Depot" },
  100033: { name: "ORBIS Corporation" },
  56617: { name: "Veritiv" },
  100066: { name: "Berlin Packaging " },
  306: { name: "LKQ" },
  100067: { name: "Shaw Industries" },
  21973: { name: "Royal Oak" },
  21974: { name: "Lam Research" },
  49666: { name: "Volvo" },
  2304: { name: "Mack Trucks Inc" },
  21975: { name: "Stord" },
  21976: { name: "FreightWeb" },
  21977: { name: "CCNA" },
  17858: { name: "Ace Hardware" },
  49661: { name: "RBW" },
  90: { name: "Dow" },
  1130: { name: "Mode Transportation" },
  1802: { name: "Nordstrom" },
  1804: { name: "Master Lock" },
  21972: { name: "Bed Bath & Beyond" },
  2090: { name: "Nestle USA" },
  1805: { name: "Archer-Daniels-Midland (ADM)" },
  63: { name: "Transplace" },
  1801: { name: "Nestle Purina" },
  41056: { name: "Uline" },
  1332: { name: "Kraft Heinz Co" },
  1331: { name: "Albertsons" },
  2095: { name: "Red Collar Pet Foods " },
  24020: { name: "Oshkosh Corporation" },
  8322: { name: "KBX Logistics (Shipper)" },
  7754: { name: "Accenture" },
  62: { name: "AB InBev" },
  8328: { name: "Alvarez & Marsal" },
  12940: { name: "Best Buy" },
  12942: { name: "Novelis" },
  12944: { name: "SSE" },
  12945: { name: "Owens Corning" },
  17855: { name: "Amneal Pharmaceuticals" },
  17853: { name: "Central Garden & Pet" },
  41055: { name: "Vineyard Vines" },
  17852: { name: "Ball Beverage Cans" },
  17850: { name: "Treehouse" },
  42134: { name: "Walgreens" },
  44198: { name: "Wells Enterprises" },
  44199: { name: "Pandion" },
  48670: { name: "Leaf" },
  48690: { name: "Coca-Cola UNITED" },
  49020: { name: "Graphic Packaging International (GPI)" },
  1690: { name: "Solvay" },
  1800: { name: "Abbott" },
  1803: { name: "BASF" },
  1831: { name: "Colgate-Palmolive" },
  2020: { name: "Johnson & Johnson" },
  49660: { name: "Clorox" },
};

async function logCart(cart, token) {
  const message = await fetch(`${graphEndpoint}/cart`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(cart)
  })
  .then((res) => res.json())
  .then((data) => console.log(data));
  
  return message;
}

class Lane {
  parseWkt() {
    const wkt = new Wkt.Wkt();
    try {
      this.geometry = wkt.read(this.path).toJson();
    } catch (e) {
      this.geometry = this.path;
    }
    this.geometry.coordinates.map((items) => items.reverse());
  }
}
class GraphResult {
  constructor(record) {
    this.lanes = [];
    this.total_miles = record.total_miles
      ? record.total_miles.toFixed(2)
      : null;
    this.total_deadhead = record.total_deadhead !== undefined
      ? record.total_deadhead.toFixed(2)
      : null;
    const colors = ["cadetblue", "blueviolet", "SaddleBrown"];

    this.total_spend = 0;
    this.total_loads = 0;
    this.average_rpm = 0;
    this.lane_count = 0;

    for (const i in [0, 1, 2]) {
      if (`l${i}` in record) {
        const lane = new Lane();
        lane.color = colors[i];
        Object.assign(lane, record[`l${i}`]);
        lane.parseWkt();
        this.lanes.push(lane);
        this.total_spend += lane.mean_spend || 0;
        this.total_loads += lane.lane_annualized_total_ships || 0;
        this.average_rpm += (lane.mean_spend || 0) / lane.miles;
        this.lane_count += 1;
      }
    }

    this.average_rpm = (this.average_rpm / this.lane_count);

    const lane_ids = this.lanes.map((lane) => lane.id);
    lane_ids.sort();
    this.move_id = lane_ids.join("-");
  }
}

class GraphQuery {
  constructor(query, token, backend) {
    this.query = query;
    // this.area = area;
    this.token = token;
    this.backend = backend;
  }

  async run() {
    console.log("Executing graph query: ", this.query);

    const results = [];
    await fetch(`${graphEndpoint}/query`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({ content: this.query, backend: this.backend.name.toLowerCase() }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        for (const record of response) {
          results.push(new GraphResult(record));
        }
      });

    const filtered = results.filter(
      (value, index, array) =>
        array.findIndex((other) => other.move_id === value.move_id) === index
    );

    return filtered;
  }
}

class ShipperGraphQuery {
  constructor(token, backend) {
    this.token = token;
    this.backend = backend;
  }

  async run() {
    const results = [];
    await fetch(`${graphEndpoint}/shippers/${this.backend.name.toLowerCase()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        for (const record of response) {
          results.push({
            name: record.owner_name,
            id: record.owner,
          });
        }
      });

    return results;
  }
}

export { ShipperGraphQuery, GraphQuery, GraphResult, Lane, companyNames, logCart };
